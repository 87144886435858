import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, CardFooter, CardHeader, CardImg, CardText, CardTitle, Col, Row } from "reactstrap";
import { join } from "lodash";
import moment from "moment/moment";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getSaleOrderInvoiceDetailById } from "store/actions";
import { saleOrderInvoiceService } from 'services/sale-order-invoice-service';
import SaleOrderAdditionalEntryForm from 'containers/sale-order/SaleOrderAdditionalEntryForm';


const SaleOrderInvoiceDetailTab = props => {
  const { getSaleOrderInvoiceDetailById, saleOrderInvoiceId, saleOrderInvoice } = props;
  const {
    item,
    loading
  } = saleOrderInvoice

  let total = 0;
  let saleOrder = item?.saleOrder;

  useEffect(() => {
    getSaleOrderInvoiceDetailById(saleOrderInvoiceId);
  }, [ saleOrderInvoiceId ]);

  const handleExportToPdf = () => {
    const { saleOrderInvoice } = props;
    if (saleOrderInvoice) {
      const { id, invoiceNo } = saleOrderInvoice.item;
      saleOrderInvoiceService.exportToPdf(id, `${invoiceNo}_sale-invoice.pdf`);
    }
  };

  return (
      <>
        {
          loading ? <>
                <div className="d-flex justify-content-center">
                  <div className="spinner-border text-primary m-1" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </> :
              <>
                {
                    item &&
                    <>
                      <Card className="mb-2">
                        <CardHeader className="bg-transparent border-bottom d-flex justify-content-between">
                          <CardTitle>Invoice No #: { item?.invoiceNo }</CardTitle>
                          <Button
                            outline
                            size="sm"
                            color="primary"
                            onClick={handleExportToPdf}
                            className="me-1"
                          >
                            <i className="fas fa-file-pdf"></i> Export to PDF
                          </Button>
                          {/*<Button*/ }
                          {/*    outline*/ }
                          {/*    size="sm"*/ }
                          {/*    color="primary"*/ }
                          {/*    // onClick={ this.handleExportToPdf }*/ }
                          {/*    className="me-1"*/ }
                          {/*>*/ }
                          {/*  <i className="fas fa-file-pdf"></i> Export to PDF*/ }
                          {/*</Button>*/ }
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md={ 3 }>
                              <address>
                                <strong>Billed To:</strong>
                                <br/>
                                <span>
                    { item.invoiceToUser }
                  </span>
                                <br/>
                                <span>
                  Phnom Penh
                                  {/*{senderAddress}*/ }
                </span>
                                <br/>
                              </address>
                            </Col>

                            <Col md={ 3 } className="text-sm-end">
                              <address className="mt-2 mt-sm-0">
                                <strong>Invoice Date:</strong>
                                <br/>
                                <span>{ moment(item.date).format("DD-MMM-YYYY") }</span>
                                <br/>
                              </address>
                              { item?.dueDate && (
                                  <address className="mt-2 mt-sm-0">
                                    <strong>Due Date</strong>
                                    <br/>
                                    <span>{ moment(item.dueDate).format("DD-MMM-YYYY") }</span>
                                    <br/>
                                  </address>
                              ) }
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Card className="mb-2">
                        <CardHeader className="pb-1 bg-transparent border-bottom">
                          <CardTitle>Products</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <table className="table-nowrap table table-bordered">
                            <thead>
                            <tr>
                              <th>Picture</th>
                              <th className="text-center">Product</th>
                              <th style={ { width: "15%" } } className="text-center">Price</th>
                              <th style={ { width: "15%" } } className="text-center">Quantity</th>
                              <th style={ { width: "15%" } } className="text-center">Discount %</th>
                              <th style={ { width: "15%" } } className="text-center">Discount Amount</th>
                              <th style={ { width: "15%" } } className="text-center">
                                Amount
                              </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                              saleOrder.saleOrderProducts.map((saleOrderProduct, index) => {
                                const { productImages } = saleOrderProduct;
                                let productImage = productImages.length > 0 ? productImages[0] : null;
                                let amount = saleOrderProduct.price * saleOrderProduct.quantity;
                                let discountAmount = amount / 100 * saleOrderProduct.discount;
                                amount = amount - discountAmount;

                                total += amount;

                                return <tr key={ index }>
                                  <td>
                                    <div className="d-flex justify-content-center">
                                      {
                                        productImage ?
                                            <img style={ { width: "80px", height: "50px", objectFit: "contain" } }
                                                 src={ productImage.url } alt=""/>
                                            :
                                            <div className="avatar-order">
                                              <i className="fas fa-solid fa-cube text-primary"></i>
                                            </div>
                                      }
                                    </div>
                                  </td>
                                  <td>
                                    <CardTitle>{ saleOrderProduct.productName }</CardTitle>
                                    <CardText><span
                                        className={ "text-muted me-2" }>Code:</span> { saleOrderProduct.productCode }
                                    </CardText>
                                  </td>
                                  <td className={ "text-end" }>
                                    <NumberFormat
                                        displayType="text"
                                        value={ Number(saleOrderProduct.price ?? 0).toFixed(2) }
                                        thousandSeparator={ true }
                                        fixedDecimalScale={ false }
                                        readOnly
                                    />
                                  </td>
                                  <td className={ "text-end" }>
                                    <NumberFormat
                                        displayType="text"
                                        value={ saleOrderProduct.quantity }
                                        thousandSeparator={ true }
                                        fixedDecimalScale={ false }
                                        readOnly
                                    />
                                  </td>
                                  <td className={ "text-end" }>
                                    <NumberFormat
                                        displayType="text"
                                        value={ Number(saleOrderProduct.discount ?? 0).toFixed(2) }
                                        thousandSeparator={ true }
                                        fixedDecimalScale={ false }
                                        readOnly
                                    />
                                  </td>
                                  <td className={ "text-end" }>
                                    <NumberFormat
                                        displayType="text"
                                        value={ Number(discountAmount).toFixed(2) }
                                        thousandSeparator={ true }
                                        fixedDecimalScale={ false }
                                        readOnly
                                    />
                                  </td>
                                  <td className={ "text-end" }>
                                    <NumberFormat
                                        displayType="text"
                                        value={ Number(amount).toFixed(2) }
                                        thousandSeparator={ true }
                                        fixedDecimalScale={ false }
                                        readOnly
                                    />
                                  </td>
                                </tr>
                              })
                            }
                            </tbody>
                            <tfoot>
                            <tr>
                              <th colSpan={ 6 } className={ "text-end" }>Total</th>
                              <th className={ "text-end" }><NumberFormat
                                  displayType="text"
                                  value={ Number(total ?? 0).toFixed(2) }
                                  thousandSeparator={ true }
                                  fixedDecimalScale={ false }
                                  readOnly
                              /></th>
                            </tr>
                            </tfoot>
                          </table>
                        </CardBody>
                        <Card> 
                          <CardBody> 
                            <CardTitle>Additional Services</CardTitle> 
                            <table className="table table-bordered"> 
                                  <tbody> 
                                    {saleOrder.saleOrderAdditionalEntries && saleOrder.saleOrderAdditionalEntries.map((entry, index) => ( 
                                      <tr key={index}> 
                                        <td>{entry.title}</td> 
                                        <td style={{ width: "15%" }} className="text-end">
                                          <NumberFormat 
                                            displayType="text" 
                                            value={Number(entry.amount ?? 0).toFixed(2)} 
                                            thousandSeparator={true} 
                                            fixedDecimalScale={false} 
                                            readOnly 
                                          />
                                        </td>
                                      </tr> ))} 
                                  </tbody> 
                            </table> 
                          </CardBody>
                        </Card>
                        <Card className="mb-2">
                          <CardHeader className="pb-1 bg-transparent border-bottom">
                            <CardTitle>Summary</CardTitle>
                          </CardHeader>
                          <CardBody>
                            <table className="table-nowrap table table-bordered" >
                              <tbody>
                                   <tr>
                                      <td className="border-0 text-end">Total Amount</td>
                                      <td className="text-sm-end" style={{ width: '180px' }}>
                                        <NumberFormat
                                          displayType="text"
                                          value={ Number(total ?? 0).toFixed(2) }
                                          thousandSeparator={ true }
                                          fixedDecimalScale={ false }
                                          readOnly
                                      /></td>
                                   </tr>
                                <tr>
                                  <td className="border-0 text-end">Discount</td>
                                  <td className="text-end">
                                    <NumberFormat
                                      value={(saleOrder.discountPromotionId?.discount || 0).toFixed(2)}
                                      displayType="text"
                                      thousandSeparator={true}
                                      fixedDecimalScale={true}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="border-0 text-end">Total Fee</td>
                                  <td className="text-end">
                                    <NumberFormat
                                      value={(() => {
                                        const total = saleOrder.saleOrderAdditionalEntries?.reduce((sum, item) => sum + (Number(item.amount) || 0), 0) || 0;
                                        return total.toFixed(2);
                                      })()}
                                      displayType="text"
                                      thousandSeparator={true}
                                      fixedDecimalScale={true}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                <td className="border-0 text-end"><strong>Grand Total</strong></td>
                                 <td className="text-end">
                                  <NumberFormat
                                    value={(() => {
                                      const totalAmount = saleOrder.saleOrderProducts.reduce((sum, item) => {
                                        const amount = item.price * item.quantity - (item.price * item.quantity * (item.discount / 100));
                                        return sum + amount;
                                      }, 0);
                                      const totalFee = saleOrder.saleOrderAdditionalEntries?.reduce((sum, entry) => {
                                        return sum + (Number(entry.amount) || 0);
                                      }, 0) || 0;
                                      const discount = saleOrder.discountPromotionId?.discount || 0;
                                      const grandTotal = totalAmount + totalFee - discount;
                                      return grandTotal.toFixed(2);
                                    })()}
                                    displayType="text"
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                  />
                                 </td>
                                </tr>
                              </tbody>
                            </table>
                          </CardBody>
                        </Card>
                      </Card>
                    </>
                }
              </>
        }

      </>
  );
};

SaleOrderInvoiceDetailTab.propTypes = {
  saleOrderInvoiceId: PropTypes.string,
  getSaleOrderInvoiceDetailById: PropTypes.func,
};

const mapStateToProps = ({ saleOrderInvoice }) => {
  return {
    saleOrderInvoice,
  }
}

export default withRouter(
    connect(mapStateToProps, {
      getSaleOrderInvoiceDetailById
    })(SaleOrderInvoiceDetailTab)
)