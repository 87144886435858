import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import Select from "react-select";
import { NIL } from "uuid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getDeliveryAgenciesRequest } from "store/delivery-agency/saga";
import moment from "moment";
import UserSelect from "../user/UserSelect";
import { localDeliveryStatusOptions } from "../../helpers/utils";

const Form = (props) => {
  const { item, onSubmit } = props;
  const [ delivery, setDelivery ] = useState({
    id: NIL,
    title: "",
    deliveryAgencyId: null,
    userId: null,
    note: "",
    date: null,
    status: localDeliveryStatusOptions[0],
  });
  
  const [ submitted, setSubmitted ] = useState(false)
  const [ deliveryAgencyOptions, setDeliveryAgencyOptions ] = useState([])
  
  useEffect(() => {
    fetchDeliveryAgencies();
  }, []);

  useEffect(() => {
    if (item) {
      setDelivery({
        ...item,
        deliveryAgencyId: item.deliveryAgencyId
          ? {
              key: item.deliveryAgencyId,
              value: item.deliveryAgencyId,
              label: item.deliveryAgency,
            }
          : null,
        date: item.date? new Date(item.date): null,
        userId: item.userId
          ? {
              key: item.userId,
              value: item.userId,
              label: item.user,
            }
          : null,
          status: {
            key: item.status,
            value: item.status,
            label: item.statusName,
          },
      })
    }else{
      setDelivery({
        id: NIL,
        title: "",
        deliveryAgencyId: null,
        userId: null,
        note: "",
        date: null,
        status: localDeliveryStatusOptions[0],
      })
    }
  }, [item]);

  
  const fetchDeliveryAgencies = () => {
    getDeliveryAgenciesRequest().then((res) => {
      let options = res.map(a=>{
        return {
          key: a.index,
          label: a.name,
          value: a.id,
        }
      })
      
      setDeliveryAgencyOptions(options);
    });
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    
    setDelivery({
      ...delivery,
      [name]: value
    })
    
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    
    setDelivery({
      ...delivery,
      [name]: selectedOption
    })
  };

  const handleSubmit = () => {
    setSubmitted(true);
    const isValid = delivery.title;
    
    if(isValid){
      let data = {
        ...delivery,
          date: delivery.date  ? moment(delivery.date).format("YYYY-MM-DDTHH:mm:ss.SSS") : null,
          deliveryAgencyId: delivery.deliveryAgencyId?.value ?? null,
          userId: delivery.userId?.value ?? null,
          status: delivery.status?.value,
      }
      
      onSubmit(data);
    }
  };
  
  return (
    <Row>
      <Col md={12}>
        <Card className="mb-3">
          <CardBody>
            <Row>
              <Col md={6}>
                <FormGroup className={submitted && ! delivery.title ? "is-invalid" : ""}>
                  <Label htmlFor="title">Title</Label>
                  <Input
                    type="text"
                    name="title"
                    className={submitted && !delivery.title ? "is-invalid" : ""}
                    placeholder="Title"
                    value={delivery.title}
                    onChange={handleChange}
                  />
                  {submitted && !delivery.title && (
                    <div className="invalid-feedback-custom">
                      Title is required.
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Date</Label>
                  <DatePicker
                    id="date"
                    name="date"
                    className={"form-control"}
                    selected={delivery.date}
                    onChange={date => setDelivery({
                      ...delivery,
                      date: date,
                    })}
                    dateFormat="dd-MMM-yyyy"
                    placeholderText="Date"
                    autoComplete="off"
                    isClearable
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup
                  className={submitted && !delivery.deliveryAgencyId ? "is-invalid" : ""}
                >
                  <Label>Delivery Agency</Label>
                  <Select
                    name="deliveryAgencyId"
                    value={delivery.deliveryAgencyId}
                    onChange={handleSelectChange}
                    options={deliveryAgencyOptions}
                    classNamePrefix="select2-selection"
                    isClearable
                  />
                  {submitted && !delivery.deliveryAgencyId && (
                    <div className="invalid-feedback-custom">
                      Delivery Agency is required.
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md={4}>
              <FormGroup>
                <Label>Driver</Label>
                <UserSelect
                  name="userId"
                  value={delivery.userId} 
                  roleNames={["Driver"]}
                  onChange={handleSelectChange}
                />
              </FormGroup> 
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>Status</Label>
                  <Select
                    name="status"
                    value={delivery.status}
                    onChange={handleSelectChange}
                    options={localDeliveryStatusOptions}
                    classNamePrefix="select2-selection"
                    isClearable
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label htmlFor="note">Description</Label>
                  <Input
                    type="textarea"
                    name="note"
                    placeholder="Description"
                    value={delivery.note}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <div className="float-end">
              <Button color="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

Form.propTypes = {
  item: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  senderTerm: PropTypes.string,
};

export default Form;
