import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Col,
  Row,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { NIL } from "uuid";
import "react-datepicker/dist/react-datepicker.css";
import UserSelect from "../user/UserSelect";
import Select from "react-select"; 
import { currencyService } from "services/currency-service";

const WalletForm = ({ item, onSubmit }) => {
  const [wallet, setWallet] = useState({
    id: NIL,
    userId: null,
    balance: 0,
    currencyId: '',
  });

  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (item) {
      const { id = NIL, userId, userFirstName, userLastName, balance = 0, currencyId, currencyLabel,  userUniqueId } = item;

      setWallet({
        id,
        userId: userId
          ? { key: userId, value: userId, label: `${userFirstName} ${userLastName}` }
          : null,
        balance,
        currencyId: currencyId
          ? { key: currencyId, value: currencyId, label: currencyLabel }
          : null,
        userUniqueId: userUniqueId || null,
      });
    } else {
      setWallet({
        id: NIL,
        userId: null,
        balance: 0,
        currencyId: '',
        userUniqueId: null,
      });
    }
  }, [item]);

  const handleChange = ({ target: { name, value } }) => {
    setWallet((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setWallet((prev) => ({
      ...prev,
      [name]: selectedOption,
    }));
  };

  const handleSubmit = () => {
    setSubmitted(true);
    const isValid = wallet.userId && wallet.currencyId && !isNaN(wallet.balance);

    if (isValid) {
      const { id, userId, balance, currencyId } = wallet;
      const data = {
        ...wallet,
        id,
        userId: userId?.value ?? null,
        balance: parseFloat(balance),
        currencyId: currencyId?.value ?? null,
      };

      onSubmit(data);
    } 
  };

  useEffect(() => {
    currencyService.getRequest().then((data) => {
      if (data) {

        const usdCurrencies = data.filter((currency) => currency.label === "USD");
        setCurrencyOptions(
          usdCurrencies.map((currency) => ({
            key: currency.id,
            value: currency.id,
            label: currency.label,
          }))
        );
      }
    });
  }, []);

  const { userId, balance, currencyId } = wallet;

  return (
    <Row>
      <Col md={12}>
        <Card className="mb-3">
          <CardBody>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>User</Label>
                  <UserSelect
                    name="userId"
                    value={userId}
                    roleNames={[]}
                    onChange={handleSelectChange}
                    className={submitted && !userId ? "is-invalid" : ""}
                    isDisabled
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="mb-3">
                  <Label htmlFor="balance">Balance</Label>
                  <Input
                    type="number"
                    name="balance"
                    disabled
                    placeholder="Balance"
                    className={"text-end"}
                    value={balance}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Currency</Label>
                  <Select
                    name="currencyId"
                    value={currencyId} 
                    onChange={handleSelectChange}
                    options={currencyOptions} 
                    classNamePrefix="select2-selection"
                    isDisabled
                  />
                  {submitted && !item.currencyId && (
                    <div className="invalid-feedback-custom">Currency is required.</div>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <div className="float-end">
              <Button color="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

WalletForm.propTypes = {
  item: PropTypes.object,
};

export default WalletForm;
