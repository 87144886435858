import {
    GET_WALLET_TRANSACTIONS,
    GET_WALLET_TRANSACTIONS_SUCCESS,
    GET_WALLET_TRANSACTIONS_ERROR,
    GET_WALLET_TRANSACTION_BY_ID,
    GET_WALLET_TRANSACTION_BY_ID_SUCCESS,
    ADD_NEW_WALLET_TRANSACTION,
    ADD_NEW_WALLET_TRANSACTION_SUCCESS,
    UPDATE_WALLET_TRANSACTION,
    UPDATE_WALLET_TRANSACTION_SUCCESS,
    DELETE_WALLET_TRANSACTION,
    DELETE_WALLET_TRANSACTION_SUCCESS,
    WALLET_TRANSACTION_CLEAR_ERROR,
    ADD_NEW_WALLET_TRANSACTION_ERROR,
  } from "./actionType";
  // WALLET_TRANSACTION
  export const getWalletTransactions = query => ({
    type: GET_WALLET_TRANSACTIONS,
    payload: query,
  });
  
  export const getWalletTransactionsSuccess = payload => ({
    type: GET_WALLET_TRANSACTIONS_SUCCESS,
    payload,
  });
  
  export const getWalletTransactionsError = error => ({
    type: GET_WALLET_TRANSACTIONS_ERROR,
    payload: error,
  });
  
  export const getWalletTransactionById = id => ({
    type: GET_WALLET_TRANSACTION_BY_ID,
    payload: id,
  });
  
  export const getWalletTransactionByIdSuccess = payload => ({
    type: GET_WALLET_TRANSACTION_BY_ID_SUCCESS,
    payload,
  });
  
  export const addNewWalletTransaction = item => ({
    type: ADD_NEW_WALLET_TRANSACTION,
    payload: item,
  });
  
  export const addNewWalletTransactionSuccess = item => ({
    type: ADD_NEW_WALLET_TRANSACTION_SUCCESS,
    payload: item,
  });
  
  export const addNewWalletTransactionError = error => ({
    type: ADD_NEW_WALLET_TRANSACTION_ERROR,
    payload: error,
  });
  
  export const updateWalletTransaction = item => ({
    type: UPDATE_WALLET_TRANSACTION,
    payload: item,
  });
  
  export const updateWalletTransactionSuccess = item => ({
    type: UPDATE_WALLET_TRANSACTIONM_SUCCESS,
    payload: item,
  });
  
  export const deleteWalletTransaction = item => ({
    type: DELETE_WALLET_TRANSACTION,
    payload: item,
  });
  
  export const deleteWalletTransactionSuccess = item => ({
    type: DELETE_WALLET_TRANSACTION_SUCCESS,
    payload: item,
  });
  
  export const clearWalletTransactionError = () => ({
    type: WALLET_TRANSACTION_CLEAR_ERROR,
  });
  