import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col, DropdownItem, DropdownMenu, DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledButtonDropdown
} from "reactstrap"
import { NIL } from "uuid"
import DatePicker from "react-datepicker"
import CreatableSelect from "react-select/creatable";
import { getMaxValue } from "../../helpers/utils"
import { debounce } from "lodash"
import StockInItemRow from "./StockInItemRow"
import moment from "moment/moment"
import StockInConfirmModal from "./StockInConfirmModal"

import ModalFormSupplierContact from 'containers/supplier-contact/ModalFormSupplierContact'
import {
  addNewSupplierRequest,
  getSupplierByIdRequest,
  getSupplierRequest
} from "store/supplier/saga";
import ProductSelectionDropdown from "../product/ProductSelectionDropdown";

const StockInForm = props => {
  const { item, onSubmit, onCancel } = props
  const [ isHeaderSelected, setIsHeaderSelected ] = useState(false)
  const [ modalConfirmStock, setModalConfirmStock ] = useState(false)
  const [ senderTerm, setSenderTerm ] = useState('');
  const [ supplierOptions, setSupplierOptions ] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [newSupplierOption, setNewSupplierOption] = useState(null);
  const [showAddSupplierModal, setShowAddSupplierModal] = useState(false); 
  
  const [ stockIn, setStockIn ] = useState({
    id: NIL,
    code: '',
    name: '',
    supplierId: null,
    status: 0,
    date: new Date(),
    stockInProducts: []
  })

  const [ isSubmitted, setIsSubmitted ] = useState(false)

  const debouncedSearchSender = useCallback(
      debounce(term => {
        setSenderTerm(term);
      }, 500),
      []
  )
  
  useEffect(() => {
    getSupplierRequest({ term: senderTerm })
      .then(res => {
        if (res.data) {
          const { data } = res;
          setSupplierOptions(data.map(supplier => ({
            key: supplier.id,
            value: supplier.id,
            label: supplier.name
          })));
        }
      })
  },[senderTerm])
  
  useEffect(() => {
    if (item) {
      setStockIn({
        ...item,
        supplierId: item.supplierId ? {
          value: item.supplierId,
          label: item.supplierName
        }: null,
        date: moment(item.date).toDate(),
        stockInProducts: item.stockInProducts.map((a, index) => {
          return {
            ...a,
            index,
            label: `${ a.productCode } - ${ a.productName }`,
            productId: {
              key: a.productId,
              value: a.productId,
              label: item.supplierName,
            },
            uomId: {
              id: a.productUomId,
              value: a.uomId,
              label: a.productUomTitle
            }
          }
        })
      })

    } else {
      setStockIn({
        id: NIL,
        code: '',
        name: '',
        supplierId: null,
        date: new Date(),
        status: 0,
        stockInProducts: []
      })
    }
  }, [ item ])
  
  const handleSelectChangeSupplier = (valueType, actionMeta) => {
    if (actionMeta.action === 'create-option') {
      setShowAddSupplierModal(true);
    } else {
      setSelectedSupplier(valueType);
      setStockIn(prevStockIn => ({
        ...prevStockIn,
        supplierId: valueType
      }));
    }
  };

  const handleSelectProduct = (product) => {
    let max = 0;
    if (stockIn.stockInProducts.length > 0) {
      max = getMaxValue(stockIn.stockInProducts.map(a => a.index));
      max += 1;
    } else {
      max = max + 1;
    }

    let newEntry={
      ...product,
      index: max,
    }

    setStockIn({ ...stockIn, stockInProducts: [...stockIn.stockInProducts, newEntry] });
  }
  
  const handleDeleteSelected = () => {
    setStockIn({ ...stockIn, stockInProducts: stockIn.stockInProducts.filter(e => !e.isSelected) })
    setIsHeaderSelected(false)
  }

  const handleHeaderSelect = e => {
    const { checked } = e.target
    setIsHeaderSelected(checked)
    setStockIn({
      ...stockIn,
      stockInProducts: stockIn.stockInProducts.map(entry => {
        return {
          ...entry,
          isSelected: checked
        }
      })
    })
  }

  const handleStockInItemChange = entry => {
    setStockIn({
      ...stockIn,
      stockInProducts: stockIn.stockInProducts.map(a => a.index === entry.index ? entry : a)
    })
  }

  const handleDeleteStockInItem = (entry) => {
    setStockIn({
      ...stockIn,
      stockInProducts: stockIn.stockInProducts.filter(e => e.index !== entry.index)
    })
  }

  const handleOnChange = (e) => {
    const { name, value } = e.target
    setStockIn({
      ...stockIn,
      [name]: value
    })
  }

  const handleOnDateChange = (name, value) => {
    setStockIn({
      ...stockIn,
      [name]: value
    })
  }

  const senderFormatOptionLabel = ({ label }) => {
    return (
        <div style={ { display: "flex", flexDirection: "column" } }>
          <p className={ "mb-1" }>
            <strong>{ label }</strong>
          </p>
        </div>
    )
  }

  const handleSenderInputChange = (value) => {
    debouncedSearchSender(value);
  }

  const handleSubmit = () => {
    setIsSubmitted(true)
    let isValid = stockIn.date && stockIn.name
    let hasNull = stockIn.stockInProducts.some(e => !e.uomId || e.quantity === 0)

    if (!hasNull && isValid) {
      let data = {
        ...stockIn,
        supplierId: stockIn.supplierId?.value,
        date: stockIn.date?.toISOString(),
      };

      if (stockIn.stockInProducts && stockIn.stockInProducts.length > 0) {
        data.stockInProducts = stockIn.stockInProducts.map(entry => ({
          ...entry,
          productId: entry.productId?.value,
          productUomId: entry.uomId?.id,
          uomId: entry.uomId?.value
        }));
      }
      onSubmit(data);
    }

  }

  const handleSubmitStockConfirmation = () => {
    setModalConfirmStock(false)
    setIsSubmitted(true)
    let isValid = stockIn.date && stockIn.name
    let hasNull = stockIn.stockInProducts.some(e => !e.uomId || e.quantity === 0)


    if (!hasNull && isValid && stockIn.stockInProducts.length > 0) {
      let data = {
        ...stockIn,
        status: 1,
        supplierId: stockIn.supplierId?.value,
        date: stockIn.date?.toISOString(),
        stockInProducts: stockIn.stockInProducts.map(entry => {
          return {
            ...entry,
            productId: entry.productId?.value,
            productUomId: entry.uomId?.id,
            uomId: entry.uomId?.value
          }
        })
      }

      onSubmit(data)
    }
  }

  const handleAddSupplier = () => {
    setShowAddSupplierModal(prevState => !prevState);
  };

  const handleNewSupplierSubmit = newSupplier => {
    addNewSupplierRequest(newSupplier)
      .then(id => {
        getSupplierByIdRequest(id).then(data => {
          if (data) {
            const newSupplierOption = {
              key: data.id,
              value: data.id,
              label: data.name
            };

            setSupplierOptions(prevOptions => [
              ...prevOptions,
              newSupplierOption
            ]);
            
            setStockIn(prevStockIn => ({
              ...prevStockIn,
              supplierId: newSupplierOption,
              name: ''
            }));
  
            setShowAddSupplierModal(false);
          }
        });
      })
  };

  return (
      <>
        <Card className={ "mb-2" }>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col>
                <CardTitle>Info</CardTitle>
              </Col>
              <Col className={ "text-end" }>
                {
                  stockIn.id !== NIL ?
                      <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="primary" outline>
                          <i className="mdi mdi-dots-vertical"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem
                              onClick={ () => setModalPrintPreview(true) }
                              className="text-primary"
                          >
                            <i className="mdi mdi-printer me-1"/> Print Preview
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown> : null
                }
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <FormGroup className="mb-3">
                  <Label htmlFor="">Code</Label>
                  <Input
                      type="text"
                      name="code"
                      placeholder="Auto Generate"
                      value={ stockIn.code }
                      readOnly
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="mb-3">
                  <Label htmlFor="">Title</Label>
                  <Input
                      type="text"
                      name="name"
                      placeholder="Title"
                      autoComplete="off"
                      className={ isSubmitted && !stockIn.name ? "is-invalid" : "" }
                      value={ stockIn.name }
                      onChange={ handleOnChange }
                  />
                  { isSubmitted && !stockIn.name && (
                      <div className="invalid-feedback-custom">Title is required.</div>
                  ) }
                </FormGroup>
              </Col>
              <Col>
                <FormGroup
                    className={
                        "select2-container" + (isSubmitted && !stockIn.date ? " is-invalid" : "")
                    }
                >
                  <Label>Stock In Date</Label>
                  <DatePicker
                      className="form-control"
                      selectsStart
                      name="date"
                      selected={ stockIn.date }
                      onChange={ obj => handleOnDateChange('date', obj) }
                      dateFormat="dd-MMM-yyyy"
                      placeholderText="Stock In Date"
                      isClearable
                  />
                </FormGroup>
                { isSubmitted && !stockIn.date && (
                    <div className="invalid-feedback-custom">
                      Stock In Date is required.
                    </div>
                ) }
              </Col>
              <Col >
                  <div className="mb-2">
                    <Label>Supplier</Label>
                    <FormGroup
                      className={
                        "select2-container d-flex " +
                        (isSubmitted && !newSupplierOption ? "is-invalid" : "")
                      }
                    >
                      <div style={{ flexGrow: "1" }}>
                        <CreatableSelect
                          name="supplierId"
                          isClearable
                          onChange={handleSelectChangeSupplier}
                          onInputChange={handleSenderInputChange}
                          options={supplierOptions}
                          value={stockIn.supplierId}
                          placeholder="Select or create a supplier..."
                          formatOptionLabel={senderFormatOptionLabel}
                        />
                      </div>
                     
                    </FormGroup>
                    {isSubmitted && !newSupplierOption && (
                      <div className="invalid-feedback-custom">
                        Shipping Contact is required.
                      </div>
                    )}
                  </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className={ "mb-2" }>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col md={ 2 }>
                <CardTitle className={ "pt-2" }>Items</CardTitle>
              </Col>
              <Col>
              </Col>
            </Row>
          </CardHeader>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col>
                <Label className={ "mt-2" }>Find Items:</Label>
              </Col>
              <Col md={ 8 }>
                <ProductSelectionDropdown
                    onSelect={handleSelectProduct}
                />
              </Col>
              <Col>
                <div className="text-sm-end">
                  <Button color={ "danger" }
                          onClick={ handleDeleteSelected }
                          outline
                          disabled={ !stockIn.stockInProducts.some(e => e.isSelected) }
                  >
                    <i className="fas fa-trash me-1"/> Delete Selected
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Table
                id="tech-companies-1"
                className="table-editable table table-striped table-bordered table-nowrap"
            >
              <thead className={ "bg-primary text-white" }>
              <tr>
                <th className={ "text-center" } style={ { width: "80px" } }>
                  <input
                      type="checkbox"
                      className="form-check-input"
                      id="headerSelected"
                      checked={ isHeaderSelected }
                      onChange={ handleHeaderSelect }
                  />
                </th>
                <th className={"text-center"} style={ { width: "120px" } }>Image</th>
                <th className="text-center">Item</th>
                <th className={ "text-center" } style={ { width: "250px" } }>Quantity</th>
                <th className={ "text-center" } style={ { width: "250px" } }>UOM</th>
                <th className={ "text-center" } style={ { width: "120px" } }>Action</th>
              </tr>
              </thead>
              <tbody>

              {
                stockIn.stockInProducts.map((entry, index) => {
                  return <StockInItemRow
                      key={ index }
                      item={ entry }
                      isSubmitted={ isSubmitted }
                      onChange={ handleStockInItemChange }
                      onDelete={ handleDeleteStockInItem }
                  />
                })
              }
              </tbody>
            </Table>
          </CardBody>
        </Card>

        <Card className={ "mb-2" }>
          <CardBody>
            {
                [ 0 ].includes(stockIn.status) && // Draft
                <Button color="primary" className={ "me-1" }
                        onClick={ handleSubmit }
                        type="submit">
                  Submit
                </Button>
            }
            {
                stockIn.id !== NIL && [ 0 ].includes(stockIn.status) && stockIn.stockInProducts.length > 0 && // Draft
                <Button color="primary" className={ "me-1" }
                        onClick={ () => setModalConfirmStock(true) }
                        type="submit">
                  Confirm
                </Button>
            }

            <Button color="secondary"
                    onClick={ () => onCancel() }
                    type="button">
              Cancel
            </Button>
          </CardBody>
        </Card>

        <StockInConfirmModal
            title="Confirm item into stock"
            isOpen={ modalConfirmStock }
            toggle={ () => setModalConfirmStock(!modalConfirmStock) }
            onSubmit={ handleSubmitStockConfirmation }
        />

        <ModalFormSupplierContact
            title="Add New Supplier"
            isOpen={showAddSupplierModal}
            toggle={handleAddSupplier}
            onSubmit={handleNewSupplierSubmit}
        />   

        {/*<PrintPreviewModal*/ }
        {/*    isOpen={ modalPrintPreview }*/ }
        {/*    stockInId={ stockIn.id }*/ }
        {/*    toggle={ () => setModalPrintPreview(!modalPrintPreview) }*/ }
        {/*/>*/ }
      </>
  )
}

StockInForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  item: PropTypes.object,
}

export default StockInForm
