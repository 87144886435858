import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { formatItemOptionLabel } from "../../helpers/utils";
import Select, { components } from "react-select";
import { getPagingProductRequest } from "../../store/product/saga";
import { getProductUomsRequest } from "../../store/product-uom/saga";
import { NIL } from "uuid";
import { debounce } from "lodash";

const customSingleValue = ({ children, data, ...props }) => {
  const { label, uniqueId } = data;
  return (
      <components.SingleValue { ...props }>
        { label } | { uniqueId }{ " " }
      </components.SingleValue>
  );
};

const ProductSelectionDropdown = props => {
  const { onSelect, isDisabled } = props;
  const [ term, setTerm ] = useState("");
  const [ productOptions, setProductOptions ] = useState([]);
  const [ loading, setLoading ] = useState(false);

  const debouncedQuickSearch = useCallback(
      debounce(term => {
        setTerm(term);
      }, 500),
      []
  );

  const handleInputChange = value => {
    debouncedQuickSearch(value);
  };
  
  useEffect(() => {
    setLoading(true);
    getPagingProductRequest({ term, page: 1, pageSize: 20 }).then(res => {
      if (res.data) {
        setLoading(false);
        setProductOptions(
            res.data.map((a, index) => {
              return {
                key: a.id,
                value: a.id,
                type: a.type,
                productImages: a.productImages,
                barcode: a.barcode,
                code: a.code,
                sku: a.sku,
                price: a.price,
                label: a.name,
              };
            })
        );
      }
    });
  }, [term]);

  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;

    switch (name) {
      case "productId":
        if (valueType) {
          const { value, label, productImages } = valueType;
          getProductUomsRequest({ productId: value }).then(res => {
            const { data } = res;
            let uomOptions =
                data?.map(a => {
                  return {
                    id: a.id,
                    value: a.uomId,
                    label: a.title,
                  };
                }) ?? [];

            let newEntry = {
              // index: max,
              id: NIL,
              productId: valueType,
              label: label,
              productImages: productImages,
              quantity: 1,
              price: valueType ? valueType.price : 0,
              discount: 0,
              total: 0,
              productUomId: null,
              uomId: uomOptions.length > 0 ? uomOptions[0] : null,
              uomOptions,
              isSelected: false,
            };
            
            onSelect(newEntry);
          });
        }
        break;
    }
  };
  
  return (
      <Select
          name="productId"
          value={ null }
          placeholder={ "Find by Code, Name, ..." }
          onChange={ handleSelectChange }
          options={ productOptions }
          components={ {
            SingleValue: customSingleValue,
          } }
          onInputChange={ handleInputChange }
          formatOptionLabel={ formatItemOptionLabel }
          classNamePrefix="select2-selection"
          isLoading={ loading }
          isDisabled = {isDisabled}
          isClearable
      />
  );
};

ProductSelectionDropdown.propTypes = {
  onSelect: PropTypes.func.isRequired
};

export default ProductSelectionDropdown;