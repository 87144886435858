import { call, put, takeEvery } from "redux-saga/effects";
import {
  ADD_NEW_WALLET_TRANSACTION,
  GET_WALLET_TRANSACTION_BY_ID,
  UPDATE_WALLET_TRANSACTION,
  DELETE_WALLET_TRANSACTION,
  GET_WALLET_TRANSACTIONS,
} from "./actionType";
import { get, post, del, put as update } from "../../helpers/api_helper";
import {
  getWalletTransactionByIdSuccess,
  getWalletTransactionsError,
  getWalletTransactionsSuccess,
} from "./actions";

const getWalletTransactionsRequest = query =>
  post("/api/WalletTransaction/paging", query);
const addNewWalletTransactionRequest = item =>
  post("/api/WalletTransaction", item);
const getWalletTransactionByIdRequest = id =>
  get(`/api/WalletTransaction/${id}`);
const updateWalletTransactionRequest = item =>
  update("/api/WalletTransaction", item);
const deleteWalletTransactionRequest = id =>
  del(`/api/WalletTransaction/${id}`);

function* getWalletTransactions({ payload }) {
  try {
    const response = yield call(getWalletTransactionsRequest, payload);
    yield put(getWalletTransactionsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getWalletTransactionsError(error));
  }
}

function* addNewWalletTransaction({ payload }) {
  try {
    const { data, queryParam } = payload;
    yield call(addNewWalletTransactionRequest, data);
    const response = yield call(getWalletTransactionsRequest, queryParam);
    yield put(getWalletTransactionsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getWalletTransactionsError(error));
  }
}

function* updateWalletTransaction({ payload }) {
  try {
    const { data, queryParam } = payload;
    yield call(updateWalletTransactionRequest, data);
    const response = yield call(getWalletTransactionsRequest, queryParam);
    yield put(getWalletTransactionsSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getWalletTransactionsError(error));
  }
}

function* getWalletTransactionById({ payload }) {
  try {
    let response = yield call(getWalletTransactionByIdRequest, payload);
    yield put(getWalletTransactionByIdSuccess(response));
  } catch (err) {
    const error = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getWalletTransactionsError(error));
  }
}

function* deleteWalletTransaction({ payload }) {
  try {
    const { data, queryParam } = payload;
    yield call(deleteWalletTransactionRequest, data);
    const response = yield call(getWalletTransactionsRequest, queryParam);
    yield put(getWalletTransactionsSuccess(response));
  } catch (error) {
    const err = err?.response?.data?.message ?? "Something has gone wrong.";
    yield put(getWalletTransactionsError(error));
  }
}

function* itemSaga() {
  yield takeEvery(GET_WALLET_TRANSACTIONS, getWalletTransactions);
  yield takeEvery(
    ADD_NEW_WALLET_TRANSACTION,
    addNewWalletTransaction
  );
  yield takeEvery(
    GET_WALLET_TRANSACTION_BY_ID,
    getWalletTransactionById
  );
  yield takeEvery(UPDATE_WALLET_TRANSACTION, updateWalletTransaction);
  yield takeEvery(DELETE_WALLET_TRANSACTION, deleteWalletTransaction);
}

export default itemSaga;

export { 
  addNewWalletTransactionRequest, 
  getWalletTransactionByIdRequest, 
  getWalletTransactionsRequest
};
