import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col, DropdownItem, DropdownMenu, DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledButtonDropdown
} from "reactstrap"
import { NIL } from "uuid"
import DatePicker from "react-datepicker"
import Select, { components } from "react-select"
import { getMaxValue } from "../../helpers/utils"
import { debounce } from "lodash"

import StockOutItemRow from "./StockOutItemRow"
import moment from "moment/moment"
import { saleOrderService } from 'services/sale-order-service'
import StockOutConfirmModal from "./StockOutConfirmModal"
import ProductSelectionDropdown from "../product/ProductSelectionDropdown";
// import PrintPreviewModal from "./PrintPreviewModal"

const StockOutForm = props => {
  const { item, onSubmit, onCancel } = props
  const [ isHeaderSelected, setIsHeaderSelected ] = useState(false)
  const [ modalConfirmStock, setModalConfirmStock ] = useState(false)
  const [ senderTerm, setSenderTerm ] = useState('')
  const [ loadingSender, setLoadingSender ] = useState(false)
  const [ saleOrderOptions, setSaleOrderOptions ] = useState([]);
  
  const [ stockOut, setStockOut ] = useState({
    id: NIL,
    code: '',
    name: '',
    saleOrderId: null,
    status: 0,
    date: new Date(),
    stockOutProducts: []
  })

  const [ isSubmitted, setIsSubmitted ] = useState(false)

  const debouncedSearchSender = useCallback(
    debounce(term => {
      setSenderTerm(term);
    }, 500),
    []
  )
  
  useEffect(() => {
    if (item) {
      setStockOut({
        ...item,
        saleOrderId: item.saleOrderId ? {
          value: item.saleOrderId,
          label: item.saleOrderNo
        }: null,
        date: moment(item.date).toDate(),
        stockOutProducts: item.stockOutProducts.map((a, index) => {
          return {
            ...a,
            index,
            label: `${ a.productCode } - ${ a.productName }`,
            productId: {
              key: a.productId,
              value: a.productId,
              label: item.orderNo,
            },
            uomId: {
              id: a.productUomId,
              value: a.uomId,
              label: a.productUomTitle
            }
          }
        })
      })

    } else {
      setStockOut({
        id: NIL,
        code: '',
        name: '',
        date: new Date(),
        status: 0,
        stockOutProducts: []
      })
    }
  }, [ item ])
  
  useEffect(() => {
    setLoadingSender(true);
    console.log("Fetching sale orders with term:", senderTerm);
    saleOrderService.getSaleOrdersRequest({ term: senderTerm }).then(records => {
      console.log("Sale orders fetched:", records);
      if (records) {
        setSaleOrderOptions(records.map(saleOrder => {
          return {
            key: saleOrder.id,
            value: saleOrder.id,
            label: saleOrder.orderNo
          };
        }));

        setLoadingSender(false);
      }
    });
  }, [ senderTerm ])

  const handleSelectProduct = (product) => {
    let max = 0;
    if (stockOut.stockOutProducts.length > 0) {
      max = getMaxValue(stockOut.stockOutProducts.map(a => a.index));
      max += 1;
    } else {
      max = max + 1;
    }

    let newEntry={
      ...product,
      index: max,
    }

    setStockOut({ ...stockOut, stockOutProducts: [...stockOut.stockOutProducts, newEntry] });
  }

  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta

    switch (name) {
      case 'saleOrderId':
        setStockOut({
          ...stockOut,
          [name]: valueType
        });
        break;
      default:
        break;
    }
    
  }

  const handleDeleteSelected = () => {
    setStockOut({ ...stockOut, stockOutProducts: stockOut.stockOutProducts.filter(e => !e.isSelected) })
    setIsHeaderSelected(false)
  }

  const handleHeaderSelect = e => {
    const { checked } = e.target
    setIsHeaderSelected(checked)
    setStockOut({
      ...stockOut,
      stockOutProducts: stockOut.stockOutProducts.map(entry => {
        return {
          ...entry,
          isSelected: checked
        }
      })
    })
  }

  const handleStockOutItemChange = entry => {
    setStockOut({
      ...stockOut,
      stockOutProducts: stockOut.stockOutProducts.map(a => a.index === entry.index ? entry : a)
    })
  }

  const handleDeleteStockOutItem = (entry) => {
    setStockOut({
      ...stockOut,
      stockOutProducts: stockOut.stockOutProducts.filter(e => e.index !== entry.index)
    })
  }

  const handleOnChange = (e) => {
    const { name, value } = e.target
    setStockOut({
      ...stockOut,
      [name]: value
    })
  }

  const handleOnDateChange = (name, value) => {
    setStockOut({
      ...stockOut,
      [name]: value
    })
  }

  const senderFormatOptionLabel = ({ label }) => {
    return (
        <div style={ { display: "flex", flexDirection: "column" } }>
          <p className={ "mb-1" }>
            <strong>{ label }</strong>
          </p>
        </div>
    )
  }

  const customSingleValue = ({
                              children,
                              data,
                              ...props
                              }) => {
  const { label } = data
  return <components.SingleValue { ...props }>{ label } </components.SingleValue>
  }

  const handleSenderInputChange = (value) => {
    debouncedSearchSender(value);
  }

  const handleSubmit = () => {
    setIsSubmitted(true)
    let isValid = stockOut.date && stockOut.name
    let hasNull = stockOut.stockOutProducts.some(e => !e.uomId || e.quantity === 0)

    if (!hasNull && isValid) {
      let data = {
        ...stockOut,
        saleOrderId: stockOut.saleOrderId?.value,
        date: stockOut.date?.toISOString(),
      };
     
    if (stockOut.stockOutProducts && stockOut.stockOutProducts.length > 0) {
        data.stockOutProducts = stockOut.stockOutProducts.map(entry => ({
          ...entry,
          productId: entry.productId?.value,
          productUomId: entry.uomId?.id,
          uomId: entry.uomId?.value
        }));
      }
      
      onSubmit(data);
    }
  }

  const handleSubmitStockConfirmation = () => {
    setModalConfirmStock(false)
    setIsSubmitted(true)
    let isValid = stockOut.date && stockOut.name
    let hasNull = stockOut.stockOutProducts.some(e => !e.uomId || e.quantity === 0)


    if (!hasNull && isValid && stockOut.stockOutProducts.length > 0) {
      let data = {
        ...stockOut,
        status: 1,
        date: stockOut.date?.toISOString(),
        stockOutProducts: stockOut.stockOutProducts.map(entry => {
          return {
            ...entry,
            productId: entry.productId?.value,
            productUomId: entry.uomId?.id,
            uomId: entry.uomId?.value
          }
        })
      }

      onSubmit(data)
    }
  }
  
  return (
      <>
        <Card className={ "mb-2" }>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col>
                <CardTitle>Info</CardTitle>
              </Col>
              <Col className={ "text-end" }>
                {
                  stockOut.id !== NIL ?
                      <UncontrolledButtonDropdown>
                        <DropdownToggle caret color="primary" outline>
                          <i className="mdi mdi-dots-vertical"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem
                              onClick={ () => setModalPrintPreview(true) }
                              className="text-primary"
                          >
                            <i className="mdi mdi-printer me-1"/> Print Preview
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown> : null
                }
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <FormGroup className="mb-3">
                  <Label htmlFor="">Code</Label>
                  <Input
                      type="text"
                      name="code"
                      placeholder="Auto Generate"
                      value={ stockOut.code }
                      readOnly
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="mb-3">
                  <Label htmlFor="">Title</Label>
                  <Input
                      type="text"
                      name="name"
                      placeholder="Title"
                      autoComplete="off"
                      className={ isSubmitted && !stockOut.name ? "is-invalid" : "" }
                      value={ stockOut.name }
                      onChange={ handleOnChange }
                  />
                  { isSubmitted && !stockOut.name && (
                      <div className="invalid-feedback-custom">Title is required.</div>
                  ) }
                </FormGroup>
              </Col>
              <Col>
                <FormGroup
                    className={
                        "select2-container" + (isSubmitted && !stockOut.date ? " is-invalid" : "")
                    }
                >
                  <Label>Stock Out Date</Label>
                  <DatePicker
                      className="form-control"
                      selectsStart
                      name="date"
                      selected={ stockOut.date }
                      onChange={ obj => handleOnDateChange('date', obj) }
                      dateFormat="dd-MMM-yyyy"
                      placeholderText="Stock In Date"
                      isClearable
                  />
                </FormGroup>
                { isSubmitted && !stockOut.date && (
                    <div className="invalid-feedback-custom">
                      Stock In Date is required.
                    </div>
                ) }
              </Col>
              <Col>
                <FormGroup>
                  <Label htmlFor="">Sale Order</Label>
                  <Select
                      name="saleOrderId"
                      value={ stockOut.saleOrderId }
                      onChange={ handleSelectChange }
                      options={ saleOrderOptions }
                      components={ {
                        SingleValue: customSingleValue
                      } }
                      onInputChange={ handleSenderInputChange }
                      formatOptionLabel={ senderFormatOptionLabel }
                      classNamePrefix="select2-selection"
                      isLoading={ loadingSender }
                      isClearable
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card className={ "mb-2" }>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col md={ 2 }>
                <CardTitle className={ "pt-2" }>Items</CardTitle>
              </Col>
              <Col>
              </Col>
            </Row>
          </CardHeader>
          <CardHeader className={ "bg-transparent border-bottom" }>
            <Row>
              <Col>
                <Label className={ "mt-2" }>Find Items:</Label>
              </Col>
              <Col md={ 8 }>
                <ProductSelectionDropdown
                    onSelect={handleSelectProduct}
                />
              </Col>
              <Col>
                <div className="text-sm-end">
                  <Button color={ "danger" }
                          onClick={ handleDeleteSelected }
                          outline
                          disabled={ !stockOut.stockOutProducts.some(e => e.isSelected) }
                  >
                    <i className="fas fa-trash me-1"/> Delete Selected
                  </Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Table
                id="tech-companies-1"
                className="table-editable table table-striped table-bordered table-nowrap"
            >
              <thead className={ "bg-primary text-white" }>
              <tr>
                <th className={ "text-center" } style={ { width: "80px" } }>
                  <input
                      type="checkbox"
                      className="form-check-input"
                      id="headerSelected"
                      checked={ isHeaderSelected }
                      onChange={ handleHeaderSelect }
                  />
                </th>
                <th className={"text-center"} style={ { width: "120px" } }>Image</th>
                <th className="text-center">Item</th>
                <th className={ "text-center" } style={ { width: "250px" } }>Quantity</th>
                <th className={ "text-center" } style={ { width: "250px" } }>UOM</th>
                <th className={ "text-center" } style={ { width: "120px" } }>Action</th>
              </tr>
              </thead>
              <tbody>

              {
                stockOut.stockOutProducts.map((entry, index) => {
                  return <StockOutItemRow
                      key={ index }
                      item={ entry }
                      isSubmitted={ isSubmitted }
                      onChange={ handleStockOutItemChange }
                      onDelete={ handleDeleteStockOutItem }
                  />
                })
              }
              </tbody>
            </Table>
          </CardBody>
        </Card>

        <Card className={ "mb-2" }>
          <CardBody>
            {
                [ 0 ].includes(stockOut.status) && // Draft
                <Button color="primary" className={ "me-1" }
                        onClick={ handleSubmit }
                        type="submit">
                  Submit
                </Button>
            }
            {
                stockOut.id !== NIL && [ 0 ].includes(stockOut.status) && stockOut.stockOutProducts.length > 0 && // Draft
                <Button color="primary" className={ "me-1" }
                        onClick={ () => setModalConfirmStock(true) }
                        type="submit">
                  Confirm
                </Button>
            }

            <Button color="secondary"
                    onClick={ () => onCancel() }
                    type="button">
              Cancel
            </Button>
          </CardBody>
        </Card>

        <StockOutConfirmModal
            title="Confirm item into stock"
            isOpen={ modalConfirmStock }
            toggle={ () => setModalConfirmStock(!modalConfirmStock) }
            onSubmit={ handleSubmitStockConfirmation }
        />
      </>
  )
}

StockOutForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  item: PropTypes.object,
}

export default StockOutForm