import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { useParams, withRouter } from "react-router-dom";
import { Badge, Container, Row, Col, Nav, NavItem, NavLink, TabPane, TabContent } from "reactstrap";
import { connect } from "react-redux";
import Breadcrumbs from "components/Common/Breadcrumb";
import { NIL } from "uuid";
import classnames from "classnames";

import {
  getDeliveryById,
  updateDelivery,
  getDeliveryItems
} from "store/actions";

import Form from "containers/delivery/Form";
import DeliveryItemTab from "./DeliveryItemTab";
import { getCompanyName } from "../../helpers/utils";

const DeliveryDetail = (props) => {
  const { 
    getDeliveryById, 
    updateDelivery, 
    getDeliveryItems,
    delivery
  } = props;
  
  const { item, loading } = delivery;
  const { id: paramId } = useParams();
  
  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    getDeliveryById(paramId);
  }, []);
  
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  
  const handlePopulateDeliveryItem = () => {
    getDeliveryItems({ deliveryId: paramId });
  };
  
  const handleNewItemsAdded = () => {
    getDeliveryItems({ deliveryId: paramId });
  };

const handlePopulateDelivery = () => {
  getDeliveryById(paramId);
};

const handleOnSubmit = (data) => {
  updateDelivery(data);
};

  return (
    <div className="page-content">
      <MetaTags>
        <title>Delivery | {getCompanyName()}</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          title="Home"
          backLink={"/delivery"}
          breadcrumbItem="Delivery Detail"
        />
        <Row>
          <Col lg={12}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                    handlePopulateDelivery();
                  }}
                >
                  General
                </NavLink>
              </NavItem>
              <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        toggle("2");
                        handlePopulateDeliveryItem();
                      }}
                    >
                      Items
                      <Badge color="success" className="ms-1">
                        {item?.itemCount}
                      </Badge>
                    </NavLink>
                  </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-border text-primary m-1"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  item && (
                    <Form
                      item={item}
                      onSubmit={handleOnSubmit}
                      showStatus
                    />
                  )
                )}
              </TabPane>
              <TabPane tabId="2">
                {paramId !== NIL && (
                  <DeliveryItemTab
                    deliveryId={paramId}
                    deliveryStatus={item?.status ?? 0}
                    onNewItemsAdded={handleNewItemsAdded}
                  />
                )}
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ delivery }) => ({
  delivery,
});

export default withRouter(
  connect(mapStateToProps, {
    getDeliveryById,
    updateDelivery,
    getDeliveryItems
  })(DeliveryDetail)
);

