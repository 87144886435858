import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, CardSubtitle, CardTitle, Col, Collapse, Row } from "reactstrap";
import moment from "moment";
import avatar from "../../assets/images/avatar.png";

const CaseAuditRowCard = props => {
  const { item } = props;
  const [ isCollapse, setIsCollapse ] = useState(true);
  
  let profile = item.avatar ??  avatar;
  return (
      <Card className="mb-2">
        <CardHeader className={"bg-transparent border-bottom"}>
          <Row>
            <Col md={1} className="text-center">
                <img className={"rounded-circle avatar-sm"}
                     src={profile} alt={item.user}/>
            </Col>
            <Col>
              <CardSubtitle className={"mb-2"}>
                <i className="fas fa-user me-1"></i>  <span className="text-black me-2">By :</span> {item.user}
              </CardSubtitle>
              <CardSubtitle>
                <i className="fas fa-calendar me-1"></i>
                <span className="text-black me-2">Date time:</span>
                <span className="text-muted"> {moment
                    .utc(item.createdDate)
                    .local()
                    .format("DD-MMM-yyyy h:mm:ss a")}</span>
              </CardSubtitle>
            </Col>
          </Row>
        </CardHeader>
        <Collapse isOpen={isCollapse}>
          <CardBody>
            <p>Data Change</p>
            <pre>{item.data}</pre>
          </CardBody>
        </Collapse>
      </Card>
  );
};

CaseAuditRowCard.propTypes = {
  item: PropTypes.object,
};

export default CaseAuditRowCard;