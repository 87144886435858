import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { MetaTags } from "react-meta-tags";
import { Col, Container, Row } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addWallet } from "../../store/wallet/actions";
import WalletForm from "containers/wallet/WalletForm";

const AddNewWallet = ({ addWallet, history, wallet }) => {
  const [activeTab, setActiveTab] = useState("1");
  const items = wallet?.items || []; 

  const handleSubmit = (data) => {
    const isDuplicate = items.some(item => item.currency === data.currency);
  
    if (isDuplicate) {
      alert("A wallet for this currency already exists!");
    } else {
      addWallet({
        data,
        history,
        queryParams: {},
      });
    }
  };

  const handleOnCancel = () => {
    history.push("/wallet");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Add New Wallet | CR TechSoft</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" backLink="/wallet" breadcrumbItem="Add New Wallet" />
          <Row>
            <Col md={12}>
              <WalletForm onSubmit={handleSubmit} onCancel={handleOnCancel} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

AddNewWallet.propTypes = {
  loading: PropTypes.bool,
  addWallet: PropTypes.func.isRequired,
  wallet: PropTypes.shape({
    items: PropTypes.array,
  }).isRequired,
};

const mapStateToProps = ({ wallet }) => ({
  wallet,
});

export default withRouter(
  connect(mapStateToProps, { 
    addWallet 
  })(AddNewWallet));
