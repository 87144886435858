import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, CardImg, CardSubtitle, CardTitle, Col, Collapse, Row } from "reactstrap";
import moment from "moment/moment";

const SaleOrderCommentCard = ({
                                item,
                                onEdit,
                                onConfirmDelete,
                                onDownload
                              }) => {
  
  const [ isOpen, setIsOpen ] = useState(false);

  return (
      <Card className="mb-2">
        <CardHeader className={ 'bg-transparent border-bottom' }>
          <Row>
            <Col>
              <div className={ 'd-flex' }>
                <i className="fas fa-user me-3 font-size-18 text-primary"></i> <CardTitle
                  className={ "mb-0" }>{ item.creatdByUser }</CardTitle>
              </div>
            </Col>
            <Col>
              <div className={ 'd-flex' }>
                <i className="fas fa-calendar me-3 font-size-18 text-primary"></i> 
                <CardTitle
                  className={ "mb-0" }>Last Updated: { moment.utc(item.updatedDate).local().format("DD-MMM-yyyy h:mm:ss a") }</CardTitle>
                
              </div>
            </Col>
            <Col>
              <div className={'d-flex justify-content-end'}>
                {
                  item.url ? <span className={'me-3'}>
                        <a href={ item.url } download={ item.fileName } target="_blank" rel="noopener noreferrer">
                          <i className="fas fa-download"></i>
                        </a>
                      </span> : null
                }
                    
                <span
                    className="font-size-14 text-primary px-2"
                    style={ { cursor: "pointer" } }
                    onClick={ () => onEdit(item.id) }
                >
                        <i className={ "fas fa-pen" }></i>
                      </span>
                <span
                    className="font-size-14 text-danger px-2"
                    style={ { cursor: "pointer" } }
                    onClick={ () => onConfirmDelete(item.id) }
                >
                        <i className="fas fa-trash"></i>
                      </span>
              </div>  
            </Col>
          </Row>

        </CardHeader>
        <CardBody>
          <div dangerouslySetInnerHTML={ { __html: item.comment } }></div>
        </CardBody>
      </Card>
  );
};

SaleOrderCommentCard.propTypes = {
  onEdit: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onDownload: PropTypes.func.isRequired,
};

export default SaleOrderCommentCard;