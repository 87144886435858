import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, FormGroup, Label, Modal, Col, Card, CardImg, CardBody, CardTitle, Input } from "reactstrap";
import { NIL } from "uuid";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Gallery from "react-fine-uploader";
import FineUploaderTraditional from "fine-uploader-wrappers";
import AttachmentCarusel from 'containers/pickup/AttachmentCarusel';
let uploader;

const ModalForm = props => {
  const { isOpen, toggle, title, saleOrderId, onSubmit } = props;

  const [ item, setItem ] = useState({
    id: NIL,
    saleOrderId: saleOrderId,
    comment: '',
    attachments: [],
    fileName: '',
    url: '',
  });

  useEffect(() => {
    console.log(item);
  }, [item]);

  const [ comment, setComment ] = useState('');
  const [ fileName, setFileName ] = useState('');
  const [ url, setUrl ] = useState('');
  const [ isSubmitted, setIsSubmitted ] = useState(false);

  
  useEffect(() => {
    uploader = new FineUploaderTraditional({
      options: {
        multiple: true,
        request: {
          method: "POST",
          endpoint: `${process.env.REACT_APP_API_URL}/api/FileUpload/saleOrderCommentAttachment`,
        },
        validation: {
          sizeLimit: 104857600, // 100MB
        },
      },
    });
 
    uploader.on("submitted", id => {
    });
  
    uploader.on("complete", (id, name, responseJSON) => {
      if (responseJSON.success) {
        setFileName(responseJSON.fileName);
        setUrl(responseJSON.url);
      } else {
        console.error(responseJSON.message);
      }
    });
  
    uploader.on("error", (id, name, errorReason) => {
      console.log(errorReason, id, name);
    }); 
  }, []); 
  
  const onOpened = () => {
    
    if(props.item){
      const { item } = props;
      setItem({
        ...item,
        saleOrderId: saleOrderId,
      })
      
      setComment(item.comment);
      setFileName(item.fileName);
      setUrl(item.url);
      
    }else{
      setIsSubmitted(false);
      
      setFileName('');
      setUrl('');
      setComment('');
      setItem({
        id: NIL,
        saleOrderId: saleOrderId,
        comment: '',
        attachments: [],
        fileName: '',
        url: '',
      })  
    }
  }

  const handleSubmit = () => {
    setIsSubmitted(true);

    if (comment) {
      let data = {
        ...item,
        comment: comment,
        saleOrderId,
        fileName: fileName,
        url: url,
      }
      console.log(data);
      onSubmit(data); 
    } 
  }
  
  return (
      <Modal
          size="md"
          onOpened={ onOpened }
          isOpen={ isOpen }
          toggle={ toggle }
          backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            { title }
          </h5>
          <button
              type="button"
              onClick={ toggle }
              className="close"
              data-dismiss="modal"
              aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <FormGroup className="mb-3">
            <Label>Comment</Label>
            <CKEditor
                className={ (isSubmitted && !comment ? "is-invalid" : "") }
                editor={ ClassicEditor }
                data={comment}
                config={{
                  height: 200,  // Set the editor height (in pixels)
                }}
                // data={ product.fullDescription }
                onReady={ editor => {
                  editor.setData(comment ?? '')
                } }
                onChange={ (event, editor) => {
                  const data = editor.getData();
                  setComment(data);
                } }
                
                onBlur={ (event, editor) => {
                  console.log('Blur.', editor);
                } }
                onFocus={ (event, editor) => {
                  console.log('Focus.', editor);
                } }
            />
            <div className={ (isSubmitted && !comment ? "is-invalid" : "") }></div>
            { isSubmitted && !comment && (
                <div className="invalid-feedback-custom">Comment is Required</div>
            ) }
          </FormGroup>
          <FormGroup>
            <Col>
              {item.attachments && item.attachments.length > 0 ?  (
                <AttachmentCarusel attachments={item.attachments} />
              ) : null}
              {
                fileName ?
                    <div className={ "mb-2" }>
                      <a href={ url } download={ fileName } target={ "_blank" }>
                        <i
                            className="mdi mdi-download font-size-18"
                        ></i>
                        <span className={ "ms-2" }>{ fileName }</span>
                      </a>
                    </div>
                    : null
              }
              <Gallery uploader={uploader} />
            </Col>
          </FormGroup> 
          <div className="row">
            {item.attachments && item.attachments.length > 0 && item.attachments.map((attachment, index) => (
              <div key={index} className="col-md-3">
                <Card>
                  <CardImg top width="100%" src={attachment.url} alt={attachment.fileName} />
                  <CardBody>
                    <CardTitle tag="h6">{attachment.fileName}</CardTitle>
                  </CardBody>
                </Card>
              </div>
            ))}
          </div>
        </div>

        <div className="modal-footer">
          <Button color="primary" onClick={ handleSubmit } type="submit">
            Submit
          </Button>
          <button
              type="button"
              onClick={ toggle }
              className="btn btn-secondary"
              data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
  );
};

ModalForm.propTypes = {
  saleOrderId: PropTypes.string,
  onSubmit: PropTypes.func
};

export default ModalForm;