import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { useParams, withRouter } from "react-router-dom";
import { Badge, Container, Row, Col, Nav, NavItem, NavLink, TabPane, TabContent } from "reactstrap";
import { connect } from "react-redux";
import Breadcrumbs from "components/Common/Breadcrumb";
import classnames from "classnames";

import { getWalletById, updateWallet, getWalletTransactions } from "store/actions";
import WalletForm from "containers/wallet/WalletForm";
import { getCompanyName } from "../../helpers/utils";
import WalletTransactionTab from "./WalletTransactionTab";

const WalletDetail = ({ getWalletById, updateWallet, wallet, getWalletTransactions }) => {
  const { item, loading } = wallet;
  const { id: paramId } = useParams();
  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    getWalletById(paramId);
  }, [paramId, getWalletById]);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handlePopulateWallet = () => {
    getWalletById({ paramId });
  };

  const handlePopulateWalletItem = () => {
    getWalletById({ paramId });
  };

  const handleOnSubmit = (data) => {
    updateWallet(data);
  };

  return (
    <div className="page-content">
      <MetaTags>
        <title>Wallet | {getCompanyName()}</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          title="Home"
          backLink="/wallet"
          breadcrumbItem="Wallet Detail"
        />
        <Row>
          <Col lg={12}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                    handlePopulateWallet();
                  }}
                >
                  General
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={ { cursor: "pointer" } }
                  className={ classnames({ active: activeTab === "2" })}
                  onClick={ () => {
                    setActiveTab("2");
                      getWalletTransactions({ walletId: paramId });
                  }}
                >
                  Transactions
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                {loading ? (
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-border text-primary m-1"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  item && (
                    <WalletForm
                      item={item}
                      onSubmit={handleOnSubmit}
                      showStatus
                    />
                  )
                )}
              </TabPane>
              <TabPane tabId="2">
                { paramId ? <WalletTransactionTab walletId={paramId} /> : null }
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = ({ wallet }) => ({ 
    wallet 
});

export default withRouter(
  connect(mapStateToProps, { 
    getWalletById, 
    updateWallet,
    getWalletTransactions 
})(WalletDetail)
);
