import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, CardImg, CardSubtitle, CardTitle, Input } from "reactstrap";
import moment from "moment";

const ProductSelectionCard = props => {
  const { product, selected, onSelect } = props;
  const { code, name, barcode, price, expiryDate, sku, productImages } = product;
  let productImage = productImages.length > 0 ? productImages[0] : null;

  const calculateDateDifference = (expiryDate) => {
    const now = moment(); 
    const expiration = moment(expiryDate); 
    const totalMonths = expiration.diff(now, 'months', true); 
    const fullMonths = Math.abs(Math.floor(totalMonths)); 
    const formattedExpiryDate = expiration.format('DD-MMM-YYYY');

    if (fullMonths > 0) {
      return `${formattedExpiryDate} (${fullMonths} month${fullMonths > 1 ? 's' : ''})`;
    } else if (fullMonths === 0) {
      return `${formattedExpiryDate} (Due this month)`;
    } else {
      return `${formattedExpiryDate} (${Math.abs(fullMonths)} month${Math.abs(fullMonths) > 1 ? 's' : ''})`;
    }
  };

  return (
      <Card className={ "clickable mb-1 border " + (selected ? 'border-primary' : '') }
            onClick={ () => onSelect(product) }>
        <CardHeader className={ "bg-transparent border-bottom p-0 d-flex" }>
          <div className={"d-flex justify-content-center align-items-center"} style={{width:"40px"}}>
            <Input
                name={ `check` }
                // onClick={ e =>
                //     this.handleRowCheck(user.id, e)
                // }
                // checked={ user.isSelect }
                type="checkbox"
                readOnly
            />
          </div>
          <div className={ "d-flex justify-content-center" } style={ { width: "100px" } }>
            { productImage ? (
                <CardImg
                    style={ { width: "90px", height: "68px", objectFit: "contain" } }
                    className={"p-1"}
                    src={ productImage.url }
                    alt={ productImage.fileName }
                />
            ) : (
                <div
                    style={ {
                      width: "100px",
                      height: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    } }
                >
                  <div className="avatar-order">
                    <i className="fas fa-solid fa-cube text-primary"></i>
                  </div>
                </div>
            ) }
          </div>
          <div className={ "flex-grow-1" }>
            <CardBody className={ "ps-0 py-2" }>
              <CardTitle>{ name }</CardTitle>
              <CardSubtitle>Barcode: { barcode }</CardSubtitle>
              <CardSubtitle>Price: { price }</CardSubtitle>
              <CardSubtitle>Expiry Date: { calculateDateDifference(expiryDate) }</CardSubtitle>
            </CardBody>
          </div>
        </CardHeader>
      </Card>
  );
};

ProductSelectionCard.propTypes = {
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
};

export default ProductSelectionCard;