import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { Table } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import CustomPagination from "components/Common/CustomPagination";
import moment from "moment";
import { displayDeliveryOrderStatus, hasAccess } from "helpers/utils";
import { permission } from "constants/permission";

const DeliveryTable = ({
  itemCount = 0,
  currentPage = 1,
  totalPage = 1,
  defaultPageSize = 10,
  items = [],
  onConfirmDelete = () => {},
  onEdit = () => {},
  loading = false,
  onChangePage = () => {},
  onPageSizeChange = () => {},
}) => {
  return (
    <>
      <div className="table-rep-plugin">
        {loading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <Card>
            <CardBody>
              <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
              >
                <Table
                  id="tech-companies-1"
                  className="table table-striped table-bordered"
                >
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Title</th>
                      <th>Agency</th>
                      <th>Driver</th>
                      <th>Item count</th>
                      <th>Status</th>
                      <th>Date</th>
                      <th style={{ width: "120px" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => {
                      const page = Number(currentPage) || 1;
                      const pageSize = Number(defaultPageSize) || 10;
                      let num = (page - 1) * pageSize + (index + 1);
                      return (
                        <tr
                          key={item.id} 
                          onDoubleClick={() => onEdit(item.id)}
                          style={{ cursor: "pointer" }}
                        >
                          <td className={"text-end"}>{num}</td>
                          <td>{item.title}</td>
                          <td>{item.deliveryAgency}</td>
                          <td>{item.user}</td>
                          <td className={"text-center"}>{item.itemCount}</td>
                          <td>{displayDeliveryOrderStatus(item)}</td>
                          <td className={"text-end"}>{item.date ? moment(item.date).format('DD-MMM-YYYY'): ''}</td>
                          <td>
                              <div className="d-flex gap-3">
                                {hasAccess(permission.delivery.read) && (
                                  <Link className="text-primary" to="#">
                                    <i
                                      className="mdi mdi-pencil font-size-18"
                                      id="edittooltip"
                                      onClick={() => onEdit(item.id)}
                                    ></i>
                                  </Link>
                                )}

                                {hasAccess(permission.delivery.delete) && (
                                  <Link className="text-danger" to="#">
                                    <i
                                      className="mdi mdi-delete font-size-18"
                                      id="deletetooltip"
                                      onClick={() => onConfirmDelete(item.id)}
                                    ></i>
                                  </Link>
                                )}
                              </div>
                            </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        )}
      </div>
      <CustomPagination
        itemCount={itemCount}
        currentPage={currentPage}
        totalPage={totalPage}
        defaultPageSize={defaultPageSize}
        pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
        onChangePage={(i) => onChangePage(i)}
        onPageSizeChange={(size) => onPageSizeChange(size)}
      />
    </>
  );
};

DeliveryTable.propTypes = {
  itemCount: PropTypes.number,
  currentPage: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  onEdit: PropTypes.func,
  loading: PropTypes.bool,
  onConfirmDelete: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
};

export default DeliveryTable;

