import React, { useState, useEffect, useCallback } from "react"
import MetaTags from "react-meta-tags"
import {
  Container,
  Button,
  Card,
  Input,
  FormGroup,
  CardHeader,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  CardBody
} from "reactstrap"
import Select from "react-select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Row, Col } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal"
import UserSelect from "containers/user/UserSelect"

import {
  getDeliveryItems,
  updateDelivery,
  deleteDelivery,
  clearDeliveryNotify
} from "../../store/actions"


import {
  deliveryOrderStatusOptions,
  getCompanyName
} from "helpers/utils"

import { debounce } from "lodash"
import moment from "moment"
import DeliveryRowCard from "../Delivery/DeliveryRowCard"
import CustomPagination from "../../components/Common/CustomPagination"

const DeliveryItemList = props => {
  const {
    getDeliveryItems,
    deleteDelivery,
    history,
    deliveryItem
  } = props
  const { loading, items, itemCount, pageCount } = deliveryItem

  const [ page, setPage ] = useState(1)
  const [ pageSize, setPageSize ] = useState(10)
  const [ selectedId, setSelectedId ] = useState(null)
  const [ term, setTerm ] = useState("")
  const [ moreActionIsOpen, setMoreActionIsOpen ] = useState(false)
  const [ startDate, setStartDate ] = useState(null)
  const [ endDate, setEndDate ] = useState(null)
  const [ statuses, setStatuses ] = useState([])
  const [ userId, setUserIds ] = useState(null)

  const [ modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen ] = useState(false)
  const [ isCollapse, setIsCollapse ] = useState(false)

  const debouncedFetchData = useCallback(
    debounce(term => {
      setPage(1)
      setTerm(term)
    }, 500),
    []
  )

  useEffect(() => {
    getDeliveryItems({
      page,
      pageSize,
      term,
      startDate: startDate
        ? moment(startDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : null,
      endDate: endDate
        ? moment(endDate).format("YYYY-MM-DDTHH:mm:ss.SSS")
        : null,
      statuses: statuses ? statuses.map(a => a.value) : [],
      userId: userId?.value
    })
  }, [ getDeliveryItems, page, pageSize, term, startDate, endDate, statuses, userId ])

  const handleOnEdit = id => {
    history.push(`/delivery/${ id }`)
  }

  const handleConfirmDelete = id => {
    setSelectedId(id)
    setModalConfirmDeleteIsOpen(true)
  }

  const handleOnAddNew = () => {
    history.push(`/delivery/add-new`)
  }

  const handleOnSearch = e => {
    const { value } = e.target
    debouncedFetchData(value)
  }

  const handleDelete = () => {
    deleteDelivery(selectedId)
    setModalConfirmDeleteIsOpen(false)
  }

  const handleExportToExcel = () => {
    // Will implement in futures
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Delivery Items | { getCompanyName() }</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Delivery Items" />
          <Row>
            <Col lg={ 12 }>
              <Card className="mb-1 rounded-3">
                <CardHeader className="bg-transparent border pb-0 pt-3">
                  <Row className="g-1">
                    <Col md={ 2 }>
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="text"
                            placeholder="Quick Search ..."
                            onChange={ handleOnSearch }
                          ></Input>
                          <i className="fas fa-search search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col md={ 4 }>
                      <UserSelect
                        id="driver-select"
                        name="userId"
                        value={ userId }
                        roleNames={ [ "Driver" ] }
                        onChange={ option => {
                          setUserIds(option)
                        } }
                        placeholder="Select Driver"
                        className="mb-0"
                        classNamePrefix="select2-selection"
                      />
                    </Col>
                    <Col md={ 2 }>
                      <FormGroup>
                        <DatePicker
                          className="form-control"
                          selectsStart
                          name="startDate"
                          selected={ startDate }
                          startDate={ startDate }
                          endDate={ endDate }
                          maxDate={ endDate }
                          onChange={ date => setStartDate(date) }
                          dateFormat="dd-MMM-yyyy"
                          placeholderText="Start Date"
                          autoComplete="off"
                          isClearable
                        />
                      </FormGroup>
                    </Col>
                    <Col md={ 2 }>
                      <FormGroup>
                        <DatePicker
                          className="form-control"
                          name="endDate"
                          selectsEnd
                          selected={ endDate }
                          startDate={ startDate }
                          endDate={ endDate }
                          minDate={ startDate }
                          onChange={ date =>
                            setEndDate(date)
                          }
                          dateFormat="dd-MMM-yyyy"
                          placeholderText="End Date"
                          autoComplete="off"
                          isClearable
                        />
                      </FormGroup>
                    </Col>

                    <Col md={ 2 }>
                      <div className="text-sm-end me-3">
                        <Button
                          type="button"
                          color="primary"
                          className="me-1"
                          onClick={ handleOnAddNew }
                        >
                          <i className="fas fa-plus" /> Add New
                        </Button>

                        <ButtonDropdown
                          isOpen={ moreActionIsOpen }
                          toggle={ () => setMoreActionIsOpen(!moreActionIsOpen) }
                        >
                          <DropdownToggle caret color="primary" outline>
                            <i className="mdi mdi-dots-vertical"></i>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem
                              onClick={ handleExportToExcel }
                              className="text-primary"
                            >
                              <i className="fas fa-file-excel me-1" /> Export to Excel
                            </DropdownItem>
                          </DropdownMenu>
                        </ButtonDropdown>
                      </div>
                    </Col>
                  </Row>
                  <div
                    style={ {
                      position: "absolute",
                      top: "1rem",
                      right: "0.5rem",
                      cursor: "pointer"
                    } }
                    onClick={ () => setIsCollapse(!isCollapse) }
                  >
                      <span className="font-size-24 text-primary ps-2 pe-2">
                        <i
                          className={
                            isCollapse
                              ? "fas fa-angle-up"
                              : "fas fa-angle-down"
                          }
                        ></i>
                      </span>
                  </div>
                </CardHeader>
                <Collapse isOpen={ isCollapse }>
                  <CardBody>
                    <Row>
                      <Col md={ 4 }>
                        <FormGroup>
                          <Select
                            name="statuses"
                            value={ statuses }
                            onChange={ option => setStatuses(option) }
                            options={ deliveryOrderStatusOptions }
                            placeholder="Statuses"
                            className="mb-0"
                            classNamePrefix="select2-selection"
                            isMulti
                            allowSelectAll
                          />

                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Collapse>
              </Card>
            </Col>
            <Col lg="12">

              <div className="table-rep-plugin">
                { loading ? (
                  <div className="d-flex justify-content-center">
                    <div
                      className="spinner-border text-primary m-1"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div>
                    { items.length > 0 &&
                      items.map((item, index) => {
                        let num = (page - 1) * pageSize + (index + 1)
                        return (
                          <DeliveryRowCard
                            key={ item.id }
                            item={ item }
                            num={ num }
                          />
                        )
                      }) }
                  </div>
                ) }
              </div>
              
              <Card>
                <CardBody>
                  <CustomPagination
                    itemCount={ itemCount }
                    currentPage={ page }
                    totalPage={ pageCount }
                    defaultPageSize={ pageSize }
                    pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
                    onChangePage={ setPage }
                    onPageSizeChange={ size => {
                      setPage(1)
                      setPageSize(size)
                    } }
                  />    
                </CardBody>
              </Card>
              
              <ConfirmDeleteModal
                title="Confirm Delete"
                isOpen={ modalConfirmDeleteIsOpen }
                toggle={ () => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen) }
                onSubmit={ handleDelete }
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = ({ deliveryItem }) => {
  return {
    deliveryItem
  }
}

export default withRouter(
  connect(mapStateToProps, {
    getDeliveryItems,
    updateDelivery,
    deleteDelivery,
    clearDeliveryNotify
  })(DeliveryItemList)
)
