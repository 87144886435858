import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CardTitle,
  Col,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
    getPagingSaleOrder,
    addSaleOrder,
    updateSaleOrder,
    getSaleOrderById,
    deleteSaleOrder,
} from "../../store/sale-order/actions";
import { debounce } from "lodash";
import SaleOrderSelectionCard from './SaleOrderSelectionCard';
import CustomPagination from "../../components/Common/CustomPagination";

const SaleOrderSelectionModal = (props) => {
  const {
    getPagingSaleOrder,
    isOpen,
    toggle,
    title,
    saleOrder,
    onSelected,
    orderParams
  } = props;

  const {
    items,
    loading,
    itemCount,
    pageCount
  } = saleOrder;

  const { deliveryStatuses } = orderParams;
  

  const [term, setTerm] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [selectedSaleOrders, setSelectedSaleOrders] = useState([]);
  

  const debouncedFetchData = useCallback(
    debounce((term) => {
      setTerm(term);
    }, 500),
    []
  );

  useEffect(() => {
    getPagingSaleOrder({
      page,
      pageSize,
      term,
      deliveryStatuses
    });
  }, [page, pageSize, term]);

  const onOpened = () => {
    setPage(1);
    setPageSize(5);
    setSelectedSaleOrders([]);
  };

  const handleSelect = () => {
    onSelected(selectedSaleOrders);
  };

  const handleRowCheck = (id, checked) => {
    const selectedOrder = items.find(order => order.id === id);
  
    if (checked) {
      setSelectedSaleOrders(prevOrders => {
        const updatedOrders = [...prevOrders, selectedOrder];
        return updatedOrders;
      });
    } else {
      setSelectedSaleOrders(prevOrders => {
        const updatedOrders = prevOrders.filter(order => order.id !== id);
        return updatedOrders;
      });
    }
  };

  return (
    <Modal
      size="lg"
      onOpened={onOpened}
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
    >
      <ModalHeader className="py-2" toggle={toggle}>
        <CardTitle>{title}</CardTitle>
      </ModalHeader>
      <ModalBody className="pb-0">
        <Row>
          <Col>
            <div
              className="search-box me-2 mb-2 d-inline-block"
              style={{ width: "100%" }}
            >
              <div className="position-relative">
                <Input
                  type="text"
                  className={"text-primary"}
                  placeholder="Quick Search ..."
                  onChange={(e) => {
                    debouncedFetchData(e.target.value);
                    //setSelectedSaleOrder(null);
                    setSelectedSaleOrders([]);
                  }}
                ></Input>
                <i className="fas fa-search search-icon" />
              </div>
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalBody>
        {loading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <>
             { items && items.map((item, index) => {
                    const selected = selectedSaleOrders.some(order => order.id === item.id); 
                    return <SaleOrderSelectionCard
                        key={ index }
                        index={ index }
                        saleOrder={ item }
                        selected={ selected }
                        onSelect={() => {}}
                        onRowCheck={ handleRowCheck }
                    />
               }) 
              }

            <CustomPagination
              itemCount={itemCount}
              currentPage={page}
              totalPage={pageCount}
              defaultPageSize={pageSize}
              pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
              onChangePage={(p) => setPage(p)}
              onPageSizeChange={(size) => {
                setPage(1);
                setPageSize(size);
              }}
            />
          </>
        )}
      </ModalBody>
      <ModalFooter className="py-2">
        <Button
          color="primary"
          disabled={selectedSaleOrders.length === 0}
          onClick={handleSelect}
          type="submit"
        >
          Select
        </Button>
        <button
          type="button"
          onClick={toggle}
          className="btn btn-secondary"
          data-dismiss="modal"
        >
          Close
        </button>
      </ModalFooter>
    </Modal>
  );
};

SaleOrderSelectionModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  title: PropTypes.string,
  onSelected: PropTypes.func,
};

const mapStateToProps = ({ saleOrder }) => {
  return {
    saleOrder,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    getPagingSaleOrder,
    addSaleOrder,
    updateSaleOrder,
    getSaleOrderById,
    deleteSaleOrder,
  })(SaleOrderSelectionModal)
);
