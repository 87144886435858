import { SEVERITY } from "helpers/utils.js";
import {
  GET_WALLET_TRANSACTIONS,
  GET_WALLET_TRANSACTIONS_SUCCESS,
  GET_WALLET_TRANSACTION_BY_ID_SUCCESS,
  GET_WALLET_TRANSACTIONS_ERROR,
  WALLET_TRANSACTION_CLEAR_ERROR,
  GET_WALLET_TRANSACTION_BY_ID,
} from "./actionType.js";
const initState = {
  items: [],
  item: null,
  error: null,
  totalAmount: 0,
  itemCount: 0,
  pageCount: 0,
  loading: false,
  message: null,
};

const Category = (state = initState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_WALLET_TRANSACTIONS:
      return {
        ...state,
        loading: true,
      };
    case GET_WALLET_TRANSACTIONS_SUCCESS:
      const { itemCount, pageCount, totalAmount, data } = payload;
      return {
        ...state,
        itemCount,
        pageCount,
        totalAmount,
        items: data,
        item: null,
        loading: false,
      };
    case GET_WALLET_TRANSACTION_BY_ID:
      return {
        ...state,
        item: null,
      };
    case GET_WALLET_TRANSACTION_BY_ID_SUCCESS:
      return {
        ...state,
        item: payload,
      };
    case GET_WALLET_TRANSACTIONS_ERROR:
      let hasUniqueCode = payload.includes("UNIQUE_ItemUnitOfMeasurement");
      return {
        ...state,
        message: {
          title: "Item",
          text: hasUniqueCode ? "Unit of Measurement already exist." : payload,
          severity: SEVERITY.DANGER,
        },
      };
    case WALLET_TRANSACTION_CLEAR_ERROR:
      return {
        ...state,
        loading: false,
        message: null,
      };
    default:
      return state;
  }
};

export default Category;
