// WALLET_TRANSACTION
export const GET_WALLET_TRANSACTIONS = "GET_WALLET_TRANSACTIONS";
export const GET_WALLET_TRANSACTIONS_SUCCESS =
  "GET_WALLET_TRANSACTIONS_SUCCESS";
export const GET_WALLET_TRANSACTIONS_ERROR =
  "GET_WALLET_TRANSACTIONS_ERROR";

export const GET_WALLET_TRANSACTION_BY_ID =
  "GET_WALLET_TRANSACTION_BY_ID";
export const GET_WALLET_TRANSACTION_BY_ID_SUCCESS =
  "GET_WALLET_TRANSACTION_BY_ID_SUCCESS";

export const ADD_NEW_WALLET_TRANSACTION =
  "ADD_NEW_WALLET_TRANSACTION";
export const ADD_NEW_WALLET_TRANSACTION_SUCCESS =
  "ADD_NEW_WALLET_TRANSACTION_SUCCESS";
export const ADD_NEW_WALLET_TRANSACTION_ERROR =
  "ADD_NEW_WALLET_TRANSACTION_ERROR";

export const UPDATE_WALLET_TRANSACTION =
  "UPDATE_WALLET_TRANSACTION";
export const UPDATE_WALLET_TRANSACTION_SUCCESS =
  "UPDATE_WALLET_TRANSACTION_SUCCESS";
export const UPDATE_WALLET_TRANSACTION_ERROR =
  "UPDATE_WALLET_TRANSACTION_ERROR";

export const DELETE_WALLET_TRANSACTION =
  "DELETE_WALLET_TRANSACTION";
export const DELETE_WALLET_TRANSACTION_SUCCESS =
  "DELETE_WALLET_TRANSACTION_SUCCESS";

export const WALLET_TRANSACTION_CLEAR_ERROR =
  "WALLET_TRANSACTION_CLEAR_ERROR";
