import CustomPagination from "components/Common/CustomPagination";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Badge,
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Table,
  UncontrolledDropdown
} from "reactstrap";
import moment from "moment";
import NumberFormat from "react-number-format";

const SaleOrderInvoiceTable = ({
                          itemCount,
                          page,
                          totalPage,
                          defaultPageSize,
                          items,
                          onEdit,
                          onExportToPdf,
                          onConfirmDelete,
                          onChangePage,
                          onPageSizeChange,
                        }) => {

  const displayStatus = item => {
    let color = ""
    const { status, statusName } = item
    switch (status) {
      case 0:
        color = "warning"
        break
      case 1:
        color = "info"
        break
      case 2:
        color = "success"
        break
      case 3:
        color = "danger"
        break
    }
    return (
        <p className={`m-0 p-0 text-${color}`}>
          {statusName}
        </p>
        // <Badge color={ color } className="px-2 py-2 btn-xs btn-outline">
        //   <span>{ statusName }</span>
        // </Badge>
    )
  }

  const displayPaymentStatus = item => {
    let color = ""
    const { paymentStatus, paymentStatusName } = item
    switch (paymentStatus) {
      case 0:
        // Pending = 0,
        color = "warning"
        break
      case 1:
        // Authorized = 1,
        color = "primary"
        break
      case 2:
        // Paid = 2,
        color = "success"
        break
      case 3:
        // PartiallyRefunded = 3
        color = "secondary"
        break
      case 4:
        // Refunded
        color = "secondary"
        break

      case 5:
        // Voided
        color = "danger"
        break
    }
    return (
        <p className={`m-0 p-0 text-${color}`}>
          {paymentStatusName}
        </p>
        // <Badge color={ color } className="px-2 py-2 btn-xs btn-outline">
        //   <span>{ paymentStatusName }</span>
        // </Badge>
    )
  }

  const displayShippingStatus = item => {
    let color = ""
    const { shippingStatus, shippingStatusName } = item
    switch (shippingStatus) {
      case 0:
        // NotYetShipped = 0,
        color = "warning"
        break
      case 1:
        // ShippingNotRequired = 1,
        color = "info"
        break
      case 2:
        // PartiallyShipped = 2,
        color = "primary"
        break
      case 3:
        // Shipped = 3,
        color = "success"
        break
      case 4:
        // Delivered = 4,
        color = "dark"
        break
    }
    return (
        <p className={`m-0 p-0 text-${color}`}>
          {shippingStatusName}
        </p>
        // <Badge color={ color } className="px-2 py-2 btn-xs btn-outline">
        //   <span>{ shippingStatusName }</span>
        // </Badge>
    )
  }
  

  return (
      <Card>
        <CardBody>
          <div className="table-rep-plugin">

            <div
                className="table-responsive mb-0"
                data-pattern="priority-columns"
            >
              <Table
                  id="tech-companies-1"
                  className="table table-striped table-bordered table-nowrap"
              >
                <thead>
                <tr>
                  <th className={"text-center"} style={ { width: "80px" } }>#</th>
                  <th>Invoice No</th>
                  <th>Order No</th>
                  <th>Status</th>
                  <th>Customer</th>
                  <th>Date</th>
                  <th>Due Date</th>
                  <th>Amount</th>
                  <th className={"text-center"} style={ { width: "120px" } }>Action</th>
                </tr>
                </thead>
                <tbody>
                { items.map((item, index) => {
                  let num = (page - 1) * defaultPageSize + (index + 1);
                  return (
                      <tr key={ index } style={ { cursor: "pointer" } } onDoubleClick={ () => onEdit(item.id) }>
                        <td style={ { textAlign: "right" } }>{ num }</td>
                        <td>{ item.invoiceNo }</td>
                        <td>{ item?.saleOrder?.orderNo }</td>
                        <td>{ displayStatus(item) }</td>
                        <td>{ item.invoiceToUser }</td>
                        <td className={"text-end"}>{ moment(item.date).format("DD-MMM-yyyy")}</td>
                        <td className={"text-end"}>{ moment(item.dueDate).format("DD-MMM-yyyy")}</td>
                        <td className="text-end">
                          <NumberFormat
                              value={ item.totalAmount }
                              displayType="text"
                              thousandSeparator={ true }
                              fixedDecimalScale={ false }
                              prefix={"$"}
                          />
                        </td>
                        <td>
                          <div className="text-center">
                            <UncontrolledDropdown>
                              <DropdownToggle className="card-drop" tag="a">
                                <i className="mdi mdi-dots-horizontal font-size-18"/>
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem onClick={ () => onEdit(item.id) }>
                                  <i className="mdi mdi-clipboard-list-outline font-size-16 text-primary me-1"/>{ " " }
                                  Detail
                                </DropdownItem>

                                <DropdownItem divider/>

                                <DropdownItem
                                    onClick={() => onExportToPdf(item.id)}
                                  >
                                    <i className="mdi mdi-file-pdf font-size-16 text-primary me-1" />{" "}
                                    Export to PDF
                                </DropdownItem>

                                <DropdownItem
                                    onClick={ () => onConfirmDelete(item.id) }
                                >
                                  <i className="mdi mdi-delete font-size-16 text-danger me-1"/>{ " " }
                                  Delete
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                          {/*<div className="d-flex justify-content-center gap-3">*/}
                          {/*  <Link className="text-primary" to="#">*/}
                          {/*    <i*/}
                          {/*        className="fas fa-eye font-size-18"*/}
                          {/*        id="edittooltip"*/}
                          {/*        onClick={ () => onEdit(item.id) }*/}
                          {/*    ></i>*/}
                          {/*  </Link>*/}
                          
                          {/*  <Link className="text-danger" to="#">*/}
                          {/*    <i*/}
                          {/*        className="mdi mdi-delete font-size-18"*/}
                          {/*        id="deletetooltip"*/}
                          {/*        onClick={ () => onConfirmDelete(item.id) }*/}
                          {/*    ></i>*/}
                          {/*  </Link>*/}
                          {/*</div>*/}
                        </td>
                      </tr>
                  );
                }) }
                </tbody>
              </Table>
            </div>
          </div>
          <CustomPagination
              itemCount={ itemCount }
              currentPage={ page }
              totalPage={ totalPage }
              defaultPageSize={ defaultPageSize }
              pageSizeOptions={ [ 5, 10, 20, 30, 40, 50, 100 ] }
              onChangePage={ i => onChangePage(i) }
              onPageSizeChange={ size => onPageSizeChange(size) }
          />
        </CardBody>
      </Card>
  );
};

SaleOrderInvoiceTable.propTypes = {
  itemCount: PropTypes.number,
  page: PropTypes.number,
  totalPage: PropTypes.number,
  defaultPageSize: PropTypes.number,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onExportToPdf: PropTypes.func,
  onConfirmDelete: PropTypes.func,
  onAddNew: PropTypes.func,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
};

export default SaleOrderInvoiceTable;
