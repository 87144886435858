import React, { useState } from "react";
import PropTypes from "prop-types";
import { Alert, Button, Modal } from "reactstrap";

const WalletDisableModalForm = ({
  isOpen,
  id,
  toggle,
  isWalletEnabled,
  onStatusChange,  
}) => {


const handleSubmit = () => {
    const newStatus = isWalletEnabled ? false : true;  

    const data = {
      id: id,
      toggle: newStatus,
    };
  
    onStatusChange(data); 

    toggle();  
  };

  const getModalMessage = () => {
    return isWalletEnabled
      ? "Are you sure you want to disable this wallet account?"
      : "Are you sure you want to enable this wallet account?";
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
      <div className="modal-header">
        <h5 className="modal-title mt-0">
            Toggle Wallet
        </h5>
        <button
          type="button"
          onClick={toggle}
          className="btn-close"
          aria-label="Close"
        />
      </div>
      <div className="modal-body">
        <Alert color="success">{getModalMessage()}</Alert>
      </div>
      <div className="modal-footer">
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit}  
        >
            Submit
        </Button>
      </div>
    </Modal>
  );
};

WalletDisableModalForm.propTypes = {
  id: PropTypes.string.isRequired, 
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  isWalletEnabled: PropTypes.bool.isRequired,
  onStatusChange: PropTypes.func.isRequired, 
};

export default WalletDisableModalForm;
