import {
    WALLET_ADD_NEW,
    WALLET_ADD_NEW_ERROR,
    WALLET_ADD_NEW_SUCCESS, 
    WALLET_DELETE_SUCCESS,
    WALLET_GET_BY_ID_ERROR,
    WALLET_GET_BY_ID_SUCCESS,
    WALLET_GET_PAGING,
    WALLET_GET_PAGING_ERROR,
    WALLET_GET_PAGING_SUCCESS,
    WALLET_UPDATE,
    WALLET_UPDATE_ERROR,
    WALLET_UPDATE_SUCCESS,
    WALLET_TOGGLE_STATUS_SUCCESS
  } from './actionType';
  import { SEVERITY, toastMessage } from "../../helpers/utils";
  // item
  const initialState = {
    item: null,
    items: [],
    loading: false,
    error: null,
    itemCount: 0,
    pageCount: 0,
    total: 0,
  };
  
  export default function initState(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case WALLET_ADD_NEW:
      case WALLET_GET_PAGING:
      case WALLET_UPDATE:
        return {
          ...state,
          loading: true,
          error: null,
          item: null,
        };
      case WALLET_ADD_NEW_ERROR:
        toastMessage({
          title: "Wallet",
          text: payload,
          severity: SEVERITY.DANGER 
        });
        return {
          ...state,
          loading: false,
          error: payload,
        };
      case WALLET_GET_PAGING_SUCCESS:
        const { itemCount, pageCount, data } = payload;
        return {
          ...state,
          loading: false,
          error: null,
          itemCount,
          pageCount,
          items: data,
        };
        
      case WALLET_DELETE_SUCCESS:
        toastMessage({
          title: "Wallet",
          text: 'Wallet has been successfully deleted.',
          severity: SEVERITY.INFO
        })
        return {
          ...state,
          loading: false,
          error: null,
        }
  
      case WALLET_UPDATE_SUCCESS:
        toastMessage({
          title: "Wallet",
          text: 'Wallet has been successfully updated.',
          severity: SEVERITY.INFO
        })
        return {
          ...state,
          loading: false,
          error: null,
          // item: payload
        };
  
      case WALLET_GET_BY_ID_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          item: payload,
        };
  
      case WALLET_ADD_NEW_SUCCESS:
        toastMessage({
          title: "Wallet",
          text: 'Wallet has been successfully added.',
          severity: SEVERITY.INFO
        })
        return {
          ...state,
          loading: false,
          error: null,
          item: payload,
        };

      case WALLET_TOGGLE_STATUS_SUCCESS:
        toastMessage({
          title: "Wallet",
          text: 'Wallet status has been set successfully.',
          severity: SEVERITY.INFO
        })
        return {
          ...state,
          loading: false,
          error: null,
          item: payload,
        };

      case WALLET_ADD_NEW_ERROR:
      case WALLET_GET_PAGING_ERROR:
      case WALLET_GET_BY_ID_ERROR:
      case WALLET_UPDATE_ERROR:
        return {
          ...state,
          loading: false,
          error: payload,
        };
  
      default:
        return { ...state };
    }
  }
  